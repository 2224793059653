<template>
    <!-- 惠生 -->
    <div id="CustomerDZMotor">
      <!-- CustomerDZMotor头部内容 -->
      <div class="CustomerDZMotorHeader">
        <!-- 头部组件 -->
        <Header />
      </div>
      <!-- CustomerDZMotor中间内容 -->
      <div class="CustomerDZMotorCenter">
        <!-- 客户介绍 -->
        <div class="contentOne">
          <div class="titleOne">客户介绍</div>
          <div class="conInfo">
            <img src="~assets/img/CustomerHS/公司@2x.png" />
            <div class="infoOne">
              &#160;&#160;&#160;&#160;&#160;舟山惠生海洋工程有限公司从事包括海洋钻井、生产平台的设计与建造，海洋桩基式固定钻井、生产平台及模块的设计与建造，海上浮式生产储卸油装置、海上浮式储卸油装置、液化天然气船、液化石油气船的设计与建造，豪华游轮的设计与建造，港口机械装备的设计与建造，各类海洋工程平台的改造与修理。<br /><br />
              &#160;&#160;&#160;&#160;&#160;公司服务了包括：Caribbean FLNG项目、EXMAR FSRU项目、浙江石化乙烯裂解录模块项目、台塑美国乙烯装置模块项目、BPZ CX-15浮力塔项目等国内大型海洋工程项目。<br /><br />
             
            </div>
          </div>
        </div>
        <!-- 项目内容 -->
        <div class="contentTwo">
          <div class="titleTwo">项目内容</div>
          <div class="titleT">
            计划资源管理：通过数字化系统的管理手段，能实时跟踪和管控各项目计划完成进度，显示项目计划节点和项目资源的运行状态，确保基地各生产项目计划按照项目里程碑节点顺利完成；资源数据做到一个平台实时统计、汇总、监控和可视化展示分析。
            <br />生产资源管理系统通过数字化的手段及时跟踪和管控，更好的能够清晰表达生产资源使用状态，提高外场生产资源的使用率、合理分配生产资源资源、紧密结合生产计划，加强生产资源管控，确保公司生产资源资源做到实时监控和可视化管理。
          </div>
        </div>
         <!-- 项目概况 -->
      <div class="contentThree">
        <ul class="imgContent">
          <li v-for="(item, index) in imgContents" :key="index">
            <img :src="item.Imgs" style="width: 550px;height: 324px;" />
            <!-- <div class="imgBottom">{{ item.textPro }}</div> -->
          </li>
        </ul>
      </div>
    
      </div>
      <!-- 底部内容 -->
      <Footer />
      <!-- 回到顶部 -->
      <el-backtop
        ><img
          src="~assets/img/CustomerDZMotor/icon-一键置顶@2x.png"
          alt="icon-回到顶部@2x.png"
      /></el-backtop>
    </div>
  </template>
  <script>
  import Header from "components/Header/dsHeader";
  import Footer from "components/Footer/dsFooter";
  export default {
    name: "CustomerDZMotor",
    data() {
      return {
        imgContents: [
          {
            Imgs: require("assets/img/board/hs/01.jpg"),
            textPro: ""
          },  {
            Imgs: require("assets/img/board/hs/02.jpg"),
            textPro: ""
          }
        ],
       
      };
    },
    mounted() {  
      document.title = '岱山汽船配工业互联网平台';   
  },
    components: {
      Header,
      Footer
    }
  };
  </script>
  <style lang="less" scope>
  #CustomerDZMotor {
     width: 1583px;
    background-color: #f8f9fb;
    margin: 0 auto;
    // CusCustomerDZMotor头部样式
    .CustomerDZMotorHeader {
      width: 1583px;
      height: 620px;
      background: url("~assets/img/CustomerHS/banner@2x.jpg")
        no-repeat;
      background-size: 1583px 620px;
      background-position: center;
      margin: 0 auto;
    }
    // CustomerDZMotor中间内容样式
    .CustomerDZMotorCenter {
      width: 1220px;
      margin: 80px auto 101px;
      // 客户介绍
      .contentOne {
        .titleOne {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 28px;
          color: #333333;
        }
        .conInfo {
          margin-top: 30px;
          display: flex;
          img {
            width: 560px;
            height: 343px;
          }
          .infoOne {
            width: 590px;
            height: 157px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 28px;
            color: rgba(51, 51, 51, 0.85);
            margin: 3px 0 0 40px;
          }
        }
      }
      // 项目价值
      .contentTwo {
        margin-top: 60px;
        .titleTwo {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 28px;
          color: #333333;
        }
        .titleT {
          width: 1200px;
          height: 72px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 28px;
          color: rgba(51, 51, 51, 0.85);
          margin-top: 30px;
        }
        .conBot {
          margin-top: 30px;
          padding: 0;
          display: flex;
          justify-content: space-between;
          width: 1220px;
          height: 146px;
  li{
    background-color: #fff;
    width: 215px;
    height: 146px;
    &:nth-child(2){
      .conBotContent{
        width: 113px;
      }
    }
     &:nth-child(3){
      .conBotContent{
        width: 85px;
      }
    }
     &:nth-child(4){
      .conBotContent{
        width: 56px;
      }
    }
     &:nth-child(5){
      .conBotContent{
        width: 155px;
      }
    }
  .conBotTit{
    display: flex;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #1E90FF;
  margin-top: 20px;
    .icons{
  width: 6px;
  height: 20px;
  background: #1E90FF;
  border-radius: 0px 3px 3px 0px;
  margin-right: 30px;
  
  }
  
  
  }
  .conBotContent{
   width: 71px;
  height: 70px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 28px;
  color: #333333;
  margin: 6px 0 0 30px;
  
    }
  }
        }
      }
      // 项目概况
      .contentThree {
        margin-top: 60px;
        .titleThree {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }
        .imgContent {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 30px;
          padding: 0;
          li {
            margin-bottom: 30px;
            background-color: #fff;
           
       
  
            .imgBottom {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: rgba(51, 51, 51, 0.85);
              margin-top: 15px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  </style>
  <style lang="less">
  .el-backtop {
    img {
      width: 30px;
      height: 30px;
    }
  }
  </style>
  